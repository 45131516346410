const AdminSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Wifi Vouchers',
    is_heading: false,
    is_active: false,
    link: {
      name: 'admin.wifi-vouchers'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Maintenances',
    name: 'sidebar.admin.maintenances',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-settings-line',
    children: [
      {
        title: 'Organizations',
        is_active: false,
        link: {
          name: 'admin.organizations'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-community-line'
      },
      {
        title: 'User Accounts',
        is_active: false,
        link: {
          name: 'admin.user-accounts'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-contacts-line'
      },
      {
        title: 'Purposes',
        is_active: false,
        link: {
          name: 'admin.purposes'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-2-line'
      },
      {
        title: 'Durations',
        is_active: false,
        link: {
          name: 'admin.durations'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-scan-line'
      },
      {
        title: 'Vouchers',
        is_active: false,
        link: {
          name: 'admin.vouchers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-price-tag-2-line'
      }
    ]
  },
  {
    title: 'Reports',
    name: 'sidebar.admin.reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Voucher By Organizations',
        is_active: false,
        link: {
          name: 'admin.reports.voucher-by-organizations'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      },
      {
        title: 'Voucher By Purposes',
        is_active: false,
        link: {
          name: 'admin.reports.voucher-by-purposes'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  }
]

export default AdminSideMenu
